import Vue from 'vue'
import store from './store'
import router from "./routes";
import App from './App.vue'

// Font Awesome
import './fontawesome.js'

// Bootstrap
import BootstrapVue from 'bootstrap-vue'
import '@comlinedev/bootstrap4/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVueIcons } from 'bootstrap-vue'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

var firebaseConfig = {
  apiKey: "AIzaSyCu_u2JwBDDsrYEuQ9I6cxUSCfJO-j-tjM",
  authDomain: "clgmbh-face2face-2021.firebaseapp.com",
  databaseURL: "https://clgmbh-face2face-2021-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "clgmbh-face2face-2021",
  storageBucket: "clgmbh-face2face-2021.appspot.com",
  messagingSenderId: "649205076396",
  appId: "1:649205076396:web:9fd2a3409ba2c556742a09"
};

firebase.initializeApp(firebaseConfig);

let db = firebase.firestore();
// let rdb = firebase.database();
let auth = firebase.auth();
let func = firebase.functions();

if (location.hostname === "localhost") {
  db.useEmulator("localhost", 8082);
  // rdb.useEmulator("localhost", 9000);
  auth.useEmulator("http://localhost:9100", { disableWarnings: true });
  func.useEmulator("localhost", 5003);
}

  
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)


// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
// .then(() => {
  firebase.auth().onAuthStateChanged(async user => {
    if(user){
      var idTokenResult = await user.getIdTokenResult()
      var isAdmin = idTokenResult.claims.admin
      if (isAdmin) {
        user.admin = 'admin'
      }
    }
    await store.dispatch("fetchUser", user)
    new Vue({
      render: h => h(App),
      router,
      store,
      beforeMount() {
        Vue.prototype.$user = firebase.auth()
        Vue.prototype.$db = db
        Vue.prototype.$func = func
        Vue.prototype.$store = firebase.app().storage('europe-west3')
      }
    }).$mount('#app')
  })
// })
