<template>
  <div id="admin">
    <b-container>
      <b-row>
        <b-col class="text-center mb-5">
          <h1>Termine</h1>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col class="text-center mb-5">
          <b-btn variant="primary" style="background-color: #4089C9; border-color: #4089C9;" class="w-100" @click="exportResellerPriority()">Download der Reseller Prioritäten</b-btn>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col class="text-center mb-5">
          <p>Wähle für jeden Termin per Drag&Drop einen Reseller und einen Hersteller.</p>
        </b-col>
      </b-row>
    </b-container>
    <div v-if="rooms" class="m-5">
      <b-row>
        <b-col cols="2">
          <h4>Räume</h4>
         <b-list-group>
          <b-list-group-item class="clickable"  v-for="(room, index) in rooms" :key="room.id" @click="selectedtRoomId = index" :class="selectedtRoomId===index && 'selected'">{{room.name}}</b-list-group-item>
        </b-list-group>
        </b-col>
        <b-col cols="4">
          <h4>Termine</h4>
          <b-list-group>
            <b-list-group-item  v-for="(meeting, index) in rooms[selectedtRoomId].meetings" :key="selectedtRoomId + index" :class="checkMeeting(selectedtRoomId, index, meeting.reseller, meeting.producer) && 'doubled'">
              <div class="d-flex justify-content-between">
                <div><p>{{meeting.time}}</p></div>
                <div><p><!--{{index+1}}--> <font-awesome-icon class="clickable" @click="clrearMe(selectedtRoomId, index)" :icon="['fas', 'trash']" /></p></div>
              </div>
              <div class="d-flex justify-content-between">
                <div :class="`dropArea ${dropState(meeting.reseller)}`" @drop='onDrop($event, {roomId: selectedtRoomId, meetingIndex: index, droped: "reseller"})' @dragover.prevent @dragenter.prevent >
                  <font-awesome-icon style="position: absolute;" v-b-popover.hover.top="'Achtung, dieser Reseller hat zwei oder mehr Termine zur gleichen Zeit!'" v-if="checkMeeting(selectedtRoomId, index, meeting.reseller, '')" :icon="['fas', 'exclamation-triangle']" />
                  <div v-if="meeting.reseller" class="d-flex align-items-center justify-content-center h-100"><div>{{resellerName(meeting.reseller)}}</div></div>
                  <div v-else class="d-flex align-items-center justify-content-center h-100"><div class="plus"><font-awesome-icon :icon="['fas', 'plus']" /></div></div>
                </div>
                <div :class="`dropArea ${dropState(meeting.producer)}`" @drop='onDrop($event, {roomId: selectedtRoomId, meetingIndex: index, droped: "producer"})' @dragover.prevent @dragenter.prevent >
                  <font-awesome-icon style="position: absolute;"  v-b-popover.hover.top="'Achtung, dieser Hersteller hat zwei oder mehr Termine zur gleichen Zeit!'" v-if="checkMeeting(selectedtRoomId, index, '', meeting.producer)" :icon="['fas', 'exclamation-triangle']" />
                  <div v-if="meeting.producer" class="d-flex align-items-center justify-content-center h-100"><div><!--<img class="dragable_logo" :src="producerLogo(meeting.producer)"/>-->{{producerName(meeting.producer)}}</div></div>
                  <div v-else class="d-flex align-items-center justify-content-center h-100"><div class="plus"><font-awesome-icon :icon="['fas', 'plus']" /></div></div>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col cols="3">
          <div class="sticky-top">
            <h4>Reseller</h4>
            <b-list-group>      
                <b-list-group-item  v-for="(element) in reseller" :key="element.id" draggable @dragstart="startDrag($event, element, 'reseller')">
                  <div class="dragArea d-flex justify-content-between align-items-center bg-white">
                  <!-- <img class="dragable_logo" :src="element.logo"/> -->
                    <div>{{element.name}}</div>
                  </div>
                </b-list-group-item>
            </b-list-group>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="sticky-top">
            <h4>Hersteller</h4>
            <b-list-group>
                <b-list-group-item  v-for="(element) in producer" :key="element.id" draggable @dragstart="startDrag($event, element, 'producer')">
                  <div class="dragArea d-flex justify-content-between align-items-center bg-white">
                    <img class="dragable_logo" :src="element.logo"/>
                    <div>{{element.name}}</div>
                  </div>
                </b-list-group-item>
            </b-list-group>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import json2xls from 'json2xls'
import FileSaver from 'file-saver';

export default {
  name: 'Admin',
  components:{
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  data(){
    return {
      selectedtRoomId: 0,
      rooms: null,
      producer: [],
      reseller: [],
      meetings: null,
      meetingTimes: [
          'Do. 14:30 Uhr',
          'Do. 15:00 Uhr',
          'Do. 15:30 Uhr',
          'Do. 16:00 Uhr',
          'Do. 16:30 Uhr',
          'Do. 17:00 Uhr',
          'Do. 17:30 Uhr',
          'Do. 18:00 Uhr',
          'Do. 18:30 Uhr',
          'Do. 19:00 Uhr',
          'Fr. 10:30 Uhr',
          'Fr. 11:00 Uhr',
          'Fr. 11:30 Uhr',
        ]
    }
  },
  methods:{
    async exportResellerPriority(){
      let exportJson= [
        {Name:'1'},
        {Name:'2'},
        {Name:'3'},
        {Name:'4'},
        {Name:'5'},
        {Name:'6'},
        {Name:'7'},
        {Name:'8'},
        {Name:'9'},
        {Name:'10'},
        {Name:'11'},
          ]
      let reseller = await this.$db.collection("reseller").get();
      let resellerData = reseller.docs.map(doc => {
        let data = doc.data()
        return data
      });

      resellerData.forEach(ressellerObj=>{
        exportJson.map((exportObj,  index) =>{
          exportObj[ressellerObj.name] = ressellerObj.selected[index].name
        })
      })

      let exceldata = json2xls(exportJson)

      const decodeBase64 = (str) => {
      try {
        return window.atob(str);
      } catch (e) {
        return str;
      }
      };

      const decoded = decodeBase64(exceldata);
      const buf = new ArrayBuffer(decoded.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== decoded.length; ++i) {
        view[i] = decoded.charCodeAt(i) & 0xFF;
      }

      const blob = new Blob([buf], {
      type: 'application/octet-stream'
      });

      FileSaver.saveAs(blob, `ResellerPrio.xlsx`)
    },
    async loadData(){
      await this.$db.collection("rooms").onSnapshot((snapshot) => {
        this.rooms = []
       snapshot.forEach(doc=>{
          let data = doc.data();
          data.id = doc.id
          // fill the array to 13 Slots
          // console.log(data.meetings)
          if(data.meetings.length < 13){
            let meetingCount = 0
            data.meetings.length >= 0 && (meetingCount = Number(data.meetings.length))
            for (let i = meetingCount; i < 13; i++) { 
              data.meetings[i]= {time: this.meetingTimes[i], producer: '', reseller: ''}
            }
          }
          // console.log(data)
          this.rooms.push(data);         
          }
        )
      });
    },
    async loadProducer(){
      let producer = await this.$db.collection("producer").get();
      this.producer = producer.docs.map(doc => {
        let data = doc.data()
            data.id = doc.id
        return data
      });
    },
    async loadReseller(){
      let reseller = await this.$db.collection("reseller")/* .where("selected", "!=", null) */.get();
      this.reseller = reseller.docs.map(doc => {
        let data = doc.data()
            data.id = doc.id
        return data
      });
    },
    startDrag: (evt, element, from) => {
      evt.dataTransfer.setData('ID', element.id)
      evt.dataTransfer.setData('from', from)
    },
    onDrop (evt, list) {
      let from = evt.dataTransfer.getData('from')
      let id = evt.dataTransfer.getData('ID')
      if(from === list.droped){
        //Save Producer
       if(from === 'producer'){
          this.rooms[list.roomId].meetings[list.meetingIndex].producer = id
          let selected = this.$db.collection("rooms").doc(`${this.rooms[list.roomId].id}`);
          selected.update({meetings: this.rooms[list.roomId].meetings})
        }
        //Save Reseller
        if(from === 'reseller'){
          this.rooms[list.roomId].meetings[list.meetingIndex].reseller = id
          let selected = this.$db.collection("rooms").doc(`${this.rooms[list.roomId].id}`);
          selected.update({meetings: this.rooms[list.roomId].meetings})
        }
      }

    },
    resellerName(id){
      const resellerId = this.reseller.findIndex(each => each.id === id)
      if(resellerId >= 0 && this.reseller[resellerId]){
        return this.reseller[resellerId].name
      }
    },
    producerName(id){
      const producerId = this.producer.findIndex(each => each.id === id)
      if(producerId >= 0 && this.producer[producerId]){
        return this.producer[producerId].name
      } 
    },
    producerLogo(id){
      const producerId = this.producer.findIndex(each => each.id === id)
      if(producerId >= 0 && this.producer[producerId]){
        return this.producer[producerId].logo
      } 
    },
    dropState(data){
      if(!data){
        return 'empty'
      } else {
        return 'done'
      }
    },
    clrearMe(room, index){
      this.rooms[room].meetings[index].reseller = ''
      this.rooms[room].meetings[index].producer = ''
      let selected = this.$db.collection("rooms").doc(`${this.rooms[room].id}`);
      selected.update({meetings: this.rooms[room].meetings})
    },
    checkMeeting(roomIndex, meetingIndex, reseller, producer){
      let thisReturn = false
      this.rooms.forEach((room, index)=>{
        if(roomIndex !== index && reseller !== "" || roomIndex !== index && producer  !== ""){
          if(room.meetings[meetingIndex].reseller === reseller && reseller){
            thisReturn = true
          }
          if(room.meetings[meetingIndex].producer === producer && producer){
            thisReturn = true
          } 
        } 
      })
    return thisReturn
    }
  },
  mounted(){
    this.loadData();
    this.loadProducer();
    this.loadReseller();
  }
}
</script>

<style scoped>
  #admin {
    padding-top: 30px;
  }
  body, div {
    -ms-user-select: None; 
    -moz-user-select: None; 
    -webkit-user-select: None; 
    user-select: None; 
  }
  .dragable_logo {
    max-width: 50px;
    max-height: 50px;
  }
  small {
    font-size: 10px;
    color: lightgray;
  }
  .selected {
    background-color: #4089C9;
    color: white;
  }
  .bg-white {
    background-color: #ffffff;
  }
  .dropArea {
    border: 1px #6f6f6f dashed;
    border-radius: 5px;
    width: 50%;
    min-height: 50px;
    margin: 3px;
    padding: 3px;
  }
  .plus {
    color: #aeaeae;
  }
  .dropArea.done{
    border: 1px solid;
    border-color: green;
  }
  .dropArea.empty{
    border-color: orange;
  }
  .dragArea{
    cursor: grab;
  }
  .clickable{
    cursor: pointer;
  }
  .doubled{
    background-color: rgb(255, 243, 221);
  }
</style>
