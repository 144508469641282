<template>
  <div id="admin">
    <b-container>
      <b-row>
        <b-col class="text-center mb-5">
          <h1>Reseller</h1>
          <p>Hier können die Reseller für die Herstellerpriorisierung erstellt und exportiert werden.</p>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col class="mb-5">
          <h3>Reseller erstellen</h3>
          <b-form-input class="mb-3" type="text" v-model="reseller.name" placeholder="Name"></b-form-input>
          <b-form-input class="mb-3" type="email" v-model="reseller.email" placeholder="E-Mail-Adresse"></b-form-input>
          <b-form-input class="mb-3" type="text" v-model="reseller.customerNumber" placeholder="Kundennummer"></b-form-input>
          <b-btn  class="mt-3" variant="primary" :disabled="!reseller.name || !reseller.email || !reseller.customerNumber" @click="saveReseller()">Speichern</b-btn>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col>
          <b-table striped hover :items="resellers" :fields="fields">
            <template #cell(id)="id">
              <b-btn @click="deleteReseller(id.value)" variant="danger">Löschen</b-btn>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="mt-5">
      <b-row>
        <b-col>
          <b-btn style="background-color: #4089C9; border-color: #4089C9;" @click="exportValidations()" class="w-100" variant="primary">EXPORT für Mailing</b-btn>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import Draggable from 'vuedraggable'
import { mapGetters } from "vuex"
import json2xls from 'json2xls'
import FileSaver from 'file-saver';

export default {
  name: 'Admin',
  components:{
    // Draggable
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  data(){
    return {
      reseller: {
        name: null,
        email: null,
        customerNumber: null
      },
      resellers: null,
      loading: true,
      fields: [
          {
            key: 'customerNumber',
            label: 'Kundennummer',
            sortable: true
          },
          {
            key: 'name',
            label: 'Name',
            sortable: true
          },
          {
            key: 'email',
            label: 'E-Mail',
            sortable: true
          },
          {
            key: 'id',
            label: ' ',
            sortable: false
          }
        ],
    }
  },
  methods:{
    loadResellers(){
     this.$db.collection("reseller").onSnapshot((snapshot) => {
       this.resellers = []
       snapshot.forEach(doc=>{
         let data = doc.data();
         data.id = doc.id
         this.resellers.push(data);         
         }
        )
      });
    },
    saveReseller(){
      this.$db.collection("reseller").doc(this.reseller.customerNumber).set({  
        customerNumber: this.reseller.customerNumber,
        email: this.reseller.email,
        name: this.reseller.name
      })
      .then(()=>{
        this.reseller = {
          name: null,
          email: null,
          customerNumber: null
        }
      });
    },
    makeRandomToken(length, option){
      var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
      if (option === 'number'){
      a = "1234567890".split("");
      }

      var b = [];  
      for (let i=0; i<length; i++) {
          let j = (Math.random() * (a.length-1)).toFixed(0);
          b[i] = a[j];
      }
      return b.join("");
    },
    deleteReseller(id){
      this.$db.collection("reseller").doc(id).delete()
    },
    exportValidations(){
      let exportJson = this.resellers
      exportJson.map(obj => obj.link = `https://face2face.comline.app/validate?tkn=${obj.id}`)

      let exceldata = json2xls(exportJson)

      const decodeBase64 = (str) => {
      try {
        return window.atob(str);
      } catch (e) {
        return str;
      }
      };

      const decoded = decodeBase64(exceldata);
      const buf = new ArrayBuffer(decoded.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== decoded.length; ++i) {
        view[i] = decoded.charCodeAt(i) & 0xFF;
      }

      const blob = new Blob([buf], {
      type: 'application/octet-stream'
      });

      FileSaver.saveAs(blob, `export.xlsx`)
    }
  },
  mounted(){
    this.loadResellers();
  }
}
</script>

<style scoped>
  #admin {
    padding-top: 30px;
  }
  body, div {
    -ms-user-select: None; 
    -moz-user-select: None; 
    -webkit-user-select: None; 
    user-select: None; 
  }
  .dragable_logo {
    max-width: 50px;
    max-height: 50px;
  }
  .dragable {
    border: 1px solid #dedede;
    background-color: #fff;
    border-radius: 0.35rem;
    padding: 4px;
    -ms-user-select: None; 
    -moz-user-select: None; 
    -webkit-user-select: None; 
    user-select: None; 
    min-height: 80px;
  }
  .dragableBox {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    border: 1px solid #dedede;
    background-color: #fff;
    border-radius: 0.35rem;
    height: 60px;
    padding: 4px 15px;
    margin: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-top: 4px solid #4089C9; 
    cursor: grab;
  }
  small {
    font-size: 10px;
    color: lightgray;
  }
</style>
