<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container">
      <router-link class="navbar-brand d-flex" to="/adminhome">
      <div style="background-color: white; padding: 5px; border-radius: 3px;">
        <img src="../assets/face2face-logo.png" style="height: 30px;"/>
      </div>
      </router-link>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto ml-3">
            <template v-if="this.user.loggedIn === true">
              <b-nav-item>
                <router-link to="/adminhome">Startseite</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/adminreseller">Reseller</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/admintermine">Termine</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/export-for-app">App-Code Export</router-link>
              </b-nav-item>            </template>
          </ul>
        </div>  
        <div>
          <template v-if="this.user.loggedIn === true">
            <a class="nav-link" @click.prevent="signOut"><font-awesome-icon :icon="['fas', 'sign-out']" /></a>
          </template>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/auth";
import { mapGetters } from "vuex"

export default {
  name: 'Navbar',

  components: {
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "login"
          });
        });
    }
  }
};
</script>
<style>
.navbar { 
  /* background-color:#4089C9 !important; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: -17px;
}
.navbar a.nav-link,
.navbar li a {
  cursor: pointer;
  color:rgb(255, 255, 255) !important;
}
.navbar a.nav-link:hover,
.navbar li a:hover {
  color:rgb(225, 225, 225)!important;
}
.navbar ul li ul li a {
  color: #3d3d3d !important;
}
.navbar ul li ul li a:hover {
    color: #676767 !important;
}
.navbar ul li ul li a:active {
    color: #fefefe !important;
}
</style>