// Vue
import Vue from 'vue'

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { 
  faEnvelope,
  faKey,
  faSignOut,
  faPlus,
  faTrash,
  faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons'

import {
  faArrowRight
} from '@fortawesome/pro-light-svg-icons'

library.add(
  faEnvelope,
  faKey,
  faSignOut,
  faPlus,
  faTrash,
  faExclamationTriangle,
  faArrowRight
)
Vue.component('font-awesome-icon', FontAwesomeIcon)