<template>
  <div class="d-flex justify-content-center align-items-center" style="width: 100%; height: 100%; position: absolute;">
    <div class="shadow p-5 border-top border-primary rounded loginBox">
      <div class="d-flex justify-content-center align-items-center">
        <div id="logoBox">
        <Logo :fill="'#000000'" :height="'33px'" :width="'33px'" class="mb-0 mr-2 d-flex align-items-center"></Logo> <span id="logotext">Face2Face Admin</span>
        </div>
      </div>
      <div v-if="error" class="d-flex alert alert-danger error">{{error}}</div>
      <form action="#" @submit.prevent="submit">
        <div class="form-group">
          <div>
            <label>E-Mail-Adresse</label>
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text"><font-awesome-icon :icon="['fas', 'envelope']" /></span>
              </b-input-group-prepend>
              <b-form-input                     
                id="email"
                type="email"
                class="form-control"
                name="email"
                value
                required
                autofocus
                v-model="form.email">
              </b-form-input>
            </b-input-group>
          </div>
        </div>
        <div>
          <div>
            <label>Passwort</label>
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text"><font-awesome-icon :icon="['fas', 'key']" /></span>
              </b-input-group-prepend>
              <b-form-input                     
                id="password"
                type="password"
                class="form-control"
                name="password"
                required
                v-model="form.password">
              </b-form-input>
            </b-input-group>
          </div>
        </div>

        <div class="form-group mt-4">
          <button type="submit" class="btn btn-secondary w-100">Login</button>
        </div>
      </form>
    </div>
        
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import Logo from '../assets/Logo'

export default {
  name: 'AdminLogin',
  components:{
    Logo
  },
  computed: {
  ...mapGetters({
    user: "user"
  })
  },
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      error: null
    };
  },
  methods: {
    submit() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(() => {
          this.user.loggedIn = false
          this.$router.replace({ name: "adminhome" });
        })
        .catch(err => {
          this.error = err.message;
        });
    },
    checkLogState(){
      if (this.user.loggedIn && this.user.data.admin) {
        // console.log(this.user.data.admin)
        this.$router.replace({ name: "adminhome" });
      }
    }
  },
  mounted(){
    this.checkLogState()
  }

};
</script>
<style scoped>
  .loginBox {
    border-width: 4px !important;
  }
  .input-group-text {
  width: 48px;
  border-right: none !important;
  background-color: #ffffff;
  }
  #email, #password {
    border-left: none; 
  }
  #logoBox{
    display: flex; 
    align-content: center;
    align-items: center;
    padding-bottom: 20px; 
  }
  #logotext {
    font-size: 30px;
  }
</style>