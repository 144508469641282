<template>
  <div id="home">
    <b-container>
      <b-row>
        <b-col class="text-center mb-5">
          <img src="../assets/face2face-logo.png" class="mb-5"/>
          <p>Bitte erstellen Sie Ihre Herstellerprioritätenliste.</p>
          <p>Ziehen Sie mit der Maus die Hersteller per Drag&Drop in Ihre Prioritätenliste.</p>
          <p>Ihre Priorisierung bildet die Basis für Ihren individuellen Terminplan für F2F. <br><b>Achtung:</b> Die Priorisierung ist nicht für Sie persönlich, sondern gilt für Sie als Kunde/Firma. Diese kann nur einmal pro Firma abgespeichert werden und wird dann final an ComLine versendet. Bitte sprechen Sie sich gegebenenfalls mit weiteren Teilnehmern aus Ihrer Firma zur Priorisierungsliste ab. </p>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-if="disableSaveButton">
      <b-row>
        <b-col class="text-center mb-5">
          <b-alert variant="success" show><b>Ihre Daten wurden erfogreich gespeichert.</b><br>Sie können jetzt keine änderungen mehr vornehmen.</b-alert>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col class="mb-3">
          <h3>Hersteller</h3>
          <div v-if="loading" class=" w-100 text-center"><b-spinner></b-spinner></div>
          <draggable class="dragable" v-if="!loading" v-model="producer" group="selection">
              <div class="dragableBox d-flex align-items-center justify-content-between" :style="`border-top-color: ${element.color};`" v-for="(element) in producer" :key="element.id">
                <img class="dragable_logo" :src="element.logo"/>
                <div>{{element.name}}</div>
                <div></div>
              </div>
          </draggable>
        </b-col>
        <b-col cols="1" style="min-height: 1000px;" class="d-flex align-items-center"><font-awesome-icon class="mt-5" style="font-size: 70px;" :icon="['fal', 'arrow-right']" /></b-col>
        <b-col>
          <h3>Ihre Prioritätenliste</h3>
          <div v-if="loading" class=" w-100 text-center"><b-spinner></b-spinner></div>
          <draggable class="dragable dragableBackground" v-if="!loading" v-model="selected" group="selection" >
            <div v-for="(element, index) in selected" :key="element.id" :class="`d-flex align-items-center justify-content-between ${checkNameAddClass(element.name)}`">
                <div style="width: 70px;"><b-badge variant="dark">Prio. {{index+1}}</b-badge></div>
              <div :class="`dragableBox d-flex align-items-center justify-content-between w-100 ${checkName(element.name)}`"  :style="`border-top-color: ${element.color};`" >
                <img class="dragable_logo" :src="element.logo"/>
                <div>{{element.name}}</div>
                <div></div>
              </div>
            </div>
          </draggable>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col>
          <div class="w-100 text-center"><p v-show="this.producer.length !== 0">Sie können erst Speichern, wenn Sie alle Hersteller in Ihrer Prioritätenliste haben.</p></div>
          <div class="d-flex justify-content-center m-3">
            <b-btn v-b-modal.modal variant="primary" style="background-color: #4089C9; border-color: #4089C9;" :disabled="this.producer.length !== 0 || disableSaveButton">Speichern</b-btn>
          </div>
        </b-col>
      </b-row>
    </b-container>
     <b-modal id="modal" title="Auswahl speichern" @ok="saveSelection()" ok-title="Speichern" cancel-title="Abbrechen" cancel-variant="danger">
      <p class="my-4">Die Auswahl kann nachträglich <b>nicht</b> geändert werden.</p>
    </b-modal>
    <b-modal id="alertModal" hide-footer>
      <template #modal-title>
        Bitte Schieben Sie alle Hersteller n ihre Prioritätenliste.
      </template>
      <b-button class="mt-3" variant="success" block @click="$bvModal.hide('alertModal')">OK</b-button>
    </b-modal>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { mapGetters } from "vuex"

export default {
  name: 'Home',
  components:{
    Draggable
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  watch: {
    selected: function (newSelected, oldSelected) {
      if (newSelected.length === oldSelected.length){
        if (newSelected[0] && newSelected[0].name !== 'ComLine'){
          let clIndex = newSelected.findIndex(d=>d.name === 'ComLine')
          console.log(clIndex)
          if(clIndex>0){
            this.selected = this.array_move(newSelected, clIndex, 0)
          }
        }
        if (newSelected[1] && newSelected[1].name !== 'Apple'){
          let clIndex = newSelected.findIndex(d=>d.name === 'Apple')
          console.log(clIndex)
          if(clIndex>0){
            this.selected = this.array_move(newSelected, clIndex, 1)
          }
        }
      } else {
       if (newSelected[0] && newSelected[0].name !== 'ComLine'){
          let clIndex = newSelected.findIndex(d=>d.name === 'ComLine')
          if(clIndex>0){
            this.selected = this.array_move(newSelected, clIndex, 0)
          }
        }
        if (newSelected[1] && newSelected[1].name !== 'Apple'){
          let clIndex = newSelected.findIndex(d=>d.name === 'Apple')
          console.log(clIndex)
          if(clIndex>0){
            this.selected = this.array_move(newSelected, clIndex, 1)
          }
        }
      }
    }
  },
  data(){
    return {
      producer: [],
      selected: [],
      loading: true,
      disableSaveButton: false
    }
  },
  methods:{
    async loadProducer(){
      let producer = await this.$db.collection("producer").get();
      this.producer = producer.docs.map(doc => {
        let data = doc.data()
            data.id = doc.id
        return data
      });
      this.loadSelected() 
    },
    async loadSelected(){
      console.log(this.user.data.uid)
      let selected = this.$db.collection("reseller").doc(`${this.user.data.uid}`);
      selected.get().then((doc) => {
          if (doc.exists) {
            doc.data().selected ? this.selected = doc.data().selected : this.selected = []
            if (!doc.data().selected){
              let idCL = this.producer.findIndex(d=>d.name == 'ComLine')
              this.selected.push(this.producer[idCL])
              let idApple = this.producer.findIndex(d=>d.name == 'Apple')
              this.selected.push(this.producer[idApple])

              this.producer = this.producer.filter(d=>d.name !== 'ComLine')
              this.producer = this.producer.filter(d=>d.name !== 'Apple')
            }

            this.selected.length > 0 ? this.clearProducer() : this.loading = false
          } else {
              console.error("No such document!");
          }
      }).catch((error) => {
          console.error("Error getting document:", error);
      });
    },
    clearProducer(){
      let selectedIds = this.selected.map(s=>{return s.id})
      this.producer = this.producer.filter(p => !selectedIds.includes(p.id))
      this.producer = this.producer.filter(p=> !p.ignoreMe)
      this.producer.length < 1 && (this.disableSaveButton = true)
      this.loading = false
    },
    saveSelection(){
      if(this.producer.length === 0){
        let selected = this.$db.collection("reseller").doc(`${this.user.data.uid}`);
        selected.update({
          selected: this.selected
        })
        .then(()=>{
          this.disableSaveButton = true
        })
      } else {
        this.$bvModal.show('alertModal')
      }
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    },
    checkName(name){
      let thisReturn = ''
      name === "Apple" || name === "ComLine" ?  (thisReturn = 'cursorNotAllowed') : null
      return (thisReturn)
    },
    checkNameAddClass(name){
      let thisReturn = ''
      name === "Apple" || name === "ComLine" ?  (thisReturn = 'grayed') : null
      return (thisReturn)
    }
  },
  mounted(){
    this.loadProducer()   
  }
}
</script>

<style scoped>
  #home {
    padding-top: 30px;
  }
  body, div {
    -ms-user-select: None; 
    -moz-user-select: None; 
    -webkit-user-select: None; 
    user-select: None; 
  }
  .dragable_logo {
    max-width: 50px;
    max-height: 50px;
  }
  .dragable {
    border: 1px solid #dedede;
    background-color: #fff;
    border-radius: 0.35rem;
    padding: 4px;
    -ms-user-select: None; 
    -moz-user-select: None; 
    -webkit-user-select: None; 
    user-select: None; 
    min-height: 1000px;
  }
  .dragableBox {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    border: 1px solid #dedede;
    background-color: #fff;
    border-radius: 0.35rem;
    height: 60px;
    padding: 4px 15px;
    margin: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-top: 4px solid #4089C9; 
    cursor: grab;
  }
  small {
    font-size: 10px;
    color: rgb(159, 159, 159);
  }
  .dragableBackground {
    background-image: url("../assets/plus.png");
    background-position: center; 
    background-repeat: repeat-y;
  }
  .cursorNotAllowed {
    cursor: not-allowed !important;
  }
  .grayed .badge-dark {
    background-color: rgb(176, 176, 176) !important;
  }
  .grayed {
    color: rgb(176, 176, 176) !important;
  }
</style>
