import Vue from 'vue';
import Validate from '../components/Validate'
import AdminLogin from '../components/AdminLogin'
import Admin from '../components/Admin'
import AdminExport from '../components/AdminAppCodeExport'
import Home from '../components/Home'
import Router from 'vue-router';
import store from '../store'

import AdminReseller from '../components/AdminReseller'
import AdminTermine from '../components/AdminTermine'
import "firebase/auth";

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { 
            path: '*',
            redirect: '/validate' 
        },
        { 
            path: '/', 
            redirect: '/validate' 
        },
        {
            path: '/validate',
            name: 'validate',
            component: Validate,
            meta: {
                requiresAuth: false,
                requiresAdminRole: false
            }
        },
        {
            path: '/home',
            name: 'home',
            component: Home,
            meta: {
                requiresAuth: true,
                requiresAdminRole: false
            }
        },
        {
            path: '/admin',
            name: 'admin',
            component: AdminLogin,
            meta: {
                requiresAuth: false,
                requiresAdminRole: false
            }
        },
        {
            path: '/adminhome',
            name: 'adminhome',
            component: Admin,
            meta: {
                requiresAuth: true,
                requiresAdminRole: true
            }
        },
        {
            path: '/admintermine',
            name: 'admintermine',
            component: AdminTermine,
            meta: {
                requiresAuth: true,
                requiresAdminRole: true
            }
        },
        {
            path: '/adminreseller',
            name: 'adminreseller',
            component: AdminReseller,
            meta: {
                requiresAuth: true,
                requiresAdminRole: true
            }
        },
        {
            path: '/export-for-app',
            name: 'exportforapp',
            component: AdminExport,
            meta: {
                requiresAuth: true,
                requiresAdminRole: true
            }
        },
]
});

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const requiresAdminRole = to.matched.some(record => record.meta.requiresAdminRole)
    const currentUser = store.getters.user.loggedIn
    const PermissionStatus = store.getters.user.loggedIn ? store.getters.user.data.admin: false

    if (to.name === 'admin') next()
    else if (to.name === 'validate' && currentUser) next({name: 'home'})
    else if (to.name === 'home' && currentUser && PermissionStatus) next({name: 'adminhome'})
    else if (requiresAuth && requiresAdminRole && currentUser && PermissionStatus) next()
    else if (requiresAuth && requiresAdminRole && currentUser && !PermissionStatus) next('admin')
    else if (requiresAuth && requiresAdminRole && !currentUser && !PermissionStatus) next('admin')
    else if (requiresAuth && requiresAdminRole && !currentUser && PermissionStatus) next('admin')
    else next()

})

export default router