<template>
  <div id="admin">
    <b-container>
      <b-row>
        <b-col class="text-center mb-5">  
          <h4>Reseller</h4>
          Angelegt: <b>{{validationCount}}</b><br>
          Prioritätenliste erstellt: <b>{{resellerCount}}</b>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col class="text-center mb-5">  
          <p>Weitere Informationen stehen derzeit nicht zur Verfügung.</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'Admin',

  data(){
    return {
      validationCount: 0,
      resellerCount: 0
    }
  },
  methods:{
    count(){
      this.$db.collection('validation').get().then(snap => {
        this.validationCount = snap.size 
      });
      this.$db.collection('reseller').where("selected", "!=", null).get().then(snap => {
        this.resellerCount = snap.size 
      });
    },
  },
  mounted(){
    this.count()
  }
}
</script>

<style scoped>
  #admin {
    padding-top: 30px;
  }
</style>
