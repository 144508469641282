<template>
  <div>
    <b-container>
      <b-row class="vh-100">
        <b-col v-if="working" class="d-flex justify-content-center align-items-center">
          <b-spinner/>
        </b-col>
        <b-col v-else class="d-flex justify-content-center align-items-center">
          <div class="loginBox">
            <div class="text-center mb-2">
              <img src="../assets/face2face-logo.png"/>
              <!-- <p>ComLine</p> -->
            </div>
              <b-alert v-if="error" show variant="danger">Bitte prüfen Sie ihren Code und den Link.</b-alert>
              <p>Bitte geben Sie ihren Validierungscode ein.</p>
              <b-form-input v-model="code" placeholder="Code"></b-form-input>
            <div class="text-center">
              <b-btn variant="primary" style="background-color: #4089C9; border-color: #4089C9;" class="m-2" @click="validateMe()">Validieren</b-btn>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import Logo from '../assets/Logo.vue'
// import router from '../routes/index';

export default {
  name: 'Validate',
  components:{
    // Logo,
  },
  data(){
    return {
      working: false,
      error: false,
      userToken: this.$route.query.tkn,
      code: undefined
    }
  },
  methods:{
    validateMe(){
      if(this.code){
        this.working = true
        let checkValidation = this.$func.httpsCallable('checkValidation');

        checkValidation({
          token: this.userToken,
          code: this.code
        })
        .then((result)=> {
          result.data.code === "1000" && this.authMe(result.data.data.token)
          result.data.code !== "1000" && this.showError()
        })
        .catch(()=> {
          this.showError()
      })
      }
    },
    authMe(token) {
      this.$user.signInWithCustomToken(token)
        .then(() => {
          this.$router.replace({ name: "home" });
          // location.reload();
          this.working = false
        })
        .catch((error) => {
          console.log(error)
          this.working = false
        });
    },
    showError() {
      this.error = true
      this.working = false
    }
  },
  mounted(){
    console.log(this.userToken)
  }
}
</script>

<style scoped>
  .loginBox {
    width: 400px; 
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 50px;
    border: 1px solid #dedede;
    border-top: 4px solid #4089C9; 
  }
</style>
