<template>
  <div id="app">
    <NavbarAdmin v-if="user.data && user.data.admin === 'admin'" />
    <Navbar v-else/>
    <br>
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import NavbarAdmin from './components/NavbarAdmin.vue'
import { mapGetters } from "vuex"

export default {
  name: 'App',
  components:{
    Navbar,
    NavbarAdmin
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
}
</script>

<style>
p, h1, h2, h3 {
  color: #575755;
}
</style>
