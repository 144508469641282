<template>
  <div id="adminExport">
    <b-container>
      <b-row>
        <b-col class="text-center mb-5">
          <h1>App-Code Export</h1>
          <p style="color: red;"><u><b>JEDE</b> änderung wird automatisch gespeichert und hat einfluss auf die Funktion der ComLine Events APP!</u></p>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row class="text-center">
        <h2 class="w-100">Hersteller</h2>
      </b-row>
      <b-row>
        <b-col cols="2" class="text-center mb-5" v-for="producer, index in producer" :key="index">
          <b>Name:</b> <b-form-input v-model="producer.data.name" placeholder="" @input="save('name', producer.id, index)"></b-form-input>
          <b>Kundenummer:</b> <b-form-input v-model="producer.data.customerNumber" placeholder=""  @input="save('customerNumber', producer.id, index)"></b-form-input>
          <p><b>Code:</b> {{producer.data.meetingCode}}</p>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row class="text-center">
        <h2 class="w-100">Reseller</h2>
        <br>
        <p class="w-100">Hier erscheinen nur Hersteller die eine Prioritätenliste erstellt haben.</p></b-row>
      <b-row>
        <b-col cols="2" class="text-center mb-5" v-for="reseller, index in reseller" :key="index">
          <b>Name:</b> <b-form-input v-model="reseller.data.name" placeholder="" @input="saveReseller('name', reseller.id, index)"></b-form-input>
          <b>Kundenummer:</b> <b-form-input v-model="reseller.data.customerNumber" placeholder=""  @input="saveReseller('customerNumber', reseller.id, index)"></b-form-input>
          <p><b>Code:</b> {{reseller.data.meetingCode}}</p>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col class="text-center" v-show="(loading)">
          <b-spinner small label="Speichern"></b-spinner>
          <p>Speichern...</p>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="mt-5 mb-5">
      <b-row>
        <b-col class="text-center">
          <p>Fehlende Codes werden beim Export generiert!</p>
          <b-btn style="background-color: #4089C9; border-color: #4089C9;" @click="exportReseller()" class="w-100 mb-3" variant="primary">EXPORT Reseller für die App</b-btn>
          <b-btn style="background-color: #4089C9; border-color: #4089C9;" @click="exportProducer()" class="w-100" variant="primary">EXPORT Hersteller für die App</b-btn>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import Draggable from 'vuedraggable'
import { mapGetters } from "vuex"
import json2xls from 'json2xls'
import FileSaver from 'file-saver';

export default {
  name: 'AdminProducer',
  components:{
    // Draggable
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  data(){
    return {
      loading: false,
      reseller: [],
      producer: []
    }
  },
  methods:{
     loadReseller(){
      this.$db.collection("reseller").onSnapshot((snapshot) => {
      this.reseller = []
       snapshot.forEach(doc=>{
         let data = doc.data();
          this.reseller.push({
            id: doc.id, 
            data
          });
         }
        )
      });
    },
     loadProducer(){
      this.$db.collection("producer").onSnapshot((snapshot) => {
      this.producer = []
       snapshot.forEach(doc=>{
         let data = doc.data();
          this.producer.push({id: doc.id, data});
         }
        )
      });
    },
    makeRandomToken(length, option){
      var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
      if (option === 'number'){
        a = "1234567890".split("");
      }

      var b = [];  
      for (let i=0; i<length; i++) {
          let j = (Math.random() * (a.length-1)).toFixed(0);
          b[i] = a[j];
      }
      return b.join("");
    },
    async exportValidations(data, name){
      name = name || 'export'

      let newData = data.map(d => d.data)
      let finalData = []
      newData.map(d => finalData.push({Name: d.name, Code: d.meetingCode, Kundennummer: d.customerNumber}))
      console.log(finalData)
      let exceldata = json2xls(finalData)

      const decodeBase64 = (str) => {
      try {
        return window.atob(str);
      } catch (e) {
        return str;
      }
      };

      const decoded = decodeBase64(exceldata);
      const buf = new ArrayBuffer(decoded.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== decoded.length; ++i) {
        view[i] = decoded.charCodeAt(i) & 0xFF;
      }

      const blob = new Blob([buf], {
      type: 'application/octet-stream'
      });

      await FileSaver.saveAs(blob, `${name}.xlsx`)
    },
    async checkForCodes(){
      await Promise.all(this.reseller.map(async (row) => {
        !row.data.meetingCode && await this.$db.collection("reseller").doc(row.id).update({meetingCode: this.makeRandomToken(6, 'number')})
      }))
      await Promise.all(this.producer.map(async (row) => {
        !row.data.meetingCode && await this.$db.collection("producer").doc(row.id).update({meetingCode: this.makeRandomToken(6, 'number')})
      }))
    },
    async exportProducer(){
      await this.checkForCodes()
      await this.exportValidations(this.producer, 'Hersteller')
    },
    async exportReseller(){
      await this.checkForCodes()
      await this.exportValidations(this.reseller, 'Reseller')
    },
    save(key, id, index){
      this.loading = true
      this.$db.collection("producer").doc(id).update({[key]: this.producer[index].data[key]})
      .then(()=>{
      this.loading = false
      })
    },
    saveReseller(key, id, index){
      this.loading = true
      this.$db.collection("reseller").doc(id).update({[key]: this.reseller[index].data[key]})
      .then(()=>{
      this.loading = false
      })
    }
  },
   mounted(){
     this.loadProducer();
     this.loadReseller();
  }
}
</script>

<style scoped>
  #admin {
    padding-top: 30px;
  }
  body, div {
    -ms-user-select: None; 
    -moz-user-select: None; 
    -webkit-user-select: None; 
    user-select: None; 
  }
  .dragable_logo {
    max-width: 50px;
    max-height: 50px;
  }
  .dragable {
    border: 1px solid #dedede;
    background-color: #fff;
    border-radius: 0.35rem;
    padding: 4px;
    -ms-user-select: None; 
    -moz-user-select: None; 
    -webkit-user-select: None; 
    user-select: None; 
    min-height: 80px;
  }
  .dragableBox {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    border: 1px solid #dedede;
    background-color: #fff;
    border-radius: 0.35rem;
    height: 60px;
    padding: 4px 15px;
    margin: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-top: 4px solid #4089C9; 
    cursor: grab;
  }
  small {
    font-size: 10px;
    color: lightgray;
  }
</style>
